import { useContext, useState } from "react";
import {
    TextField,
    Autocomplete,
    IconButton,
    Box,
    Snackbar,
    Alert,
    useMediaQuery,
    Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../logo.svg";
import SearchIcon from "@mui/icons-material/Search";
import { ErrorContext } from "../utils/errorContext";
import service_uris from "../utils/uri";

function DBA({ facilityNames, sendRequest, finDBA }) {
    return (
        <Autocomplete
            disablePortal
            id="searchInput"
            name="dba"
            onInputChange={(event, value) => sendRequest(event, value)}
            onChange={(event, value) => {
                sendRequest(event, value);
            }}
            options={facilityNames}
            loading={true}
            filterOptions={(x) => x}
            renderInput={(params) => (
                <TextField {...params} label="Facility Name" />
            )}
        />
    );
}

function ZipCode({ zipCodes, setZip }) {
    return (
        <Autocomplete
            id="zipInput"
            name="zipCodes"
            options={zipCodes}
            loading={true}
            onChange={(event, value) => setZip(value)}
            filterOptions={(x) => x}
            renderInput={(params) => <TextField {...params} label="Zip Code" />}
        />
    );
}

export default function SearchNpi() {
    const [dbaNames, setDbaNames] = useState([
        "start typing to see suggestions",
    ]);
    const [inProgress, setInProgress] = useState(false);
    const [zipCodes, setZipCodes] = useState(["1", "2"]);
    const [dba, setDba] = useState("");
    const [zip, setZip] = useState("");
    const navigation = useNavigate();
    const errorContext = useContext(ErrorContext);
    const matches = useMediaQuery("(max-width:600px)");

    const getValues = async (text) => {
        setInProgress(true);
        try {
            const response = await fetch(
                service_uris["typesense"] +
                    "/collections/provider_details/documents/search?q=" +
                    text +
                    "&query_by=provider_dba",
                {
                    headers: {
                        "X-TYPESENSE-API-KEY": `${process.env.REACT_APP_TYPESENSE_API_KEY}`,
                        "Access-Control-Allow-Headers": "*",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "*",
                    },
                }
            );
            const data = await response.json();
            const results = data.hits.map((hit) => hit.document.provider_dba);
            let zipCodes = data.hits.map((hit) => hit.document.zip_codes);
            zipCodes = zipCodes.flat();
            setDbaNames(results);
            setZipCodes(zipCodes);
            setInProgress(false);
        } catch (err) {
            console.log(err);
        }
    };

    const sendRequest = async (event, value) => {
        // if (event.type === "change") {
        //     await getValues(value);
        //     setDba(value);
        // }
        if (inProgress) {
            return;
        }
        await getValues(value);
        setDba(value);

        // console.log(results);
        // console.log(zipCodes);
    };

    const gotoSecond = () => {
        navigation("/services", { state: { dba: dba, zipCode: zip } });
    };

    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        errorContext.setShowError(false);
    };

    return (
        <Box className="h-screen w-screen flex flex-col">
            <img
                src={logo}
                alt="carecadet logo"
                className="h-1/2 pt-[100px] pr-[50px] w-2/5 mx-auto"
            />
            <Box className="flex flex-col flex-wrap mx-auto w-full justify-center md:flex-row">
                <Box className="p-2 md:w-1/3">
                    <DBA
                        facilityNames={dbaNames}
                        className="fields"
                        sendRequest={sendRequest}
                    />
                </Box>
                <Box className="p-2 md:w-80">
                    <ZipCode
                        zipCodes={zipCodes}
                        className="fields"
                        setZip={setZip}
                    />
                </Box>
                <Box className="p-2" onClick={gotoSecond}>
                    {matches ? (
                        <Button className="w-full" variant="contained">
                            Search
                        </Button>
                    ) : (
                        <IconButton size="large">
                            <SearchIcon fontSize="inherit" />
                        </IconButton>
                    )}
                </Box>
            </Box>
            <Snackbar
                open={errorContext.showError}
                autoHideDuration={2000}
                onClose={handleAlertClose}
            >
                <Alert
                    severity="error"
                    variant="filled"
                    sx={{ width: "100%" }}
                    onClose={handleAlertClose}
                >
                    {errorContext.errorMsg}
                </Alert>
            </Snackbar>
        </Box>
    );
}
